import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import LanguageContext from './contexts/LanguageContext';
import ScrollToTop from './components/helpers/ScrollToTop';
import { Helmet } from 'react-helmet';
import { ViewType } from './constants/types';
import MainPage from './components/body/MainPage';
import { createMuiTheme, ThemeProvider } from '@material-ui/core';
import i18n from './i18n';
import ServiceUnavailable from './components/body/ServiceUnavailable';

const App = () => {
  const { t } = useTranslation();
  const [language, setLanguage] = useState('en');

  const setLanguageWithi18n = (value: string) => {
    setLanguage(value);
    i18n.changeLanguage(value);
  };

  const modifyLanguageIfNeeded = () => {
    let langFromPath: string = '';
    switch (window.location.pathname) {
      case '/en':
      case '/en/draw':
        langFromPath = 'en';
        break;
      case '/hu':
      case '/hu/draw':
        langFromPath = 'hu';
        break;
      default:
        // for unknonwn paths, default to english
        langFromPath = 'en';
    }

    if (language !== langFromPath) setLanguageWithi18n(langFromPath);
  };

  const theme = createMuiTheme({
    palette: {
      primary: {
        main: '#DBDCDD'
      }
    }
  });

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        {modifyLanguageIfNeeded()}
        <ScrollToTop>
          <LanguageContext.Provider value={{ language, setLanguageWithi18n }}>
            <Helmet>
              <title>{t('pageInfo.title')}</title>
              <meta name="description" content={t('pageInfo.description')} />
              <meta name="keywords" content={t('pageInfo.keywords')} />
              <meta name="author" content={t('pageInfo.author')} />
            </Helmet>
            <Switch>
              {/* <Route
                exact
                path={`${process.env.PUBLIC_URL}/`}
                component={() => <MainPage currentView={ViewType.Main} />}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/en`}
                component={() => <MainPage currentView={ViewType.Main} />}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/hu`}
                component={() => <MainPage currentView={ViewType.Main} />}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/draw`}
                component={() => <MainPage currentView={ViewType.Draw} />}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/en/draw`}
                component={() => <MainPage currentView={ViewType.Draw} />}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/hu/draw`}
                component={() => <MainPage currentView={ViewType.Draw} />}
              /> */}
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/`}
                component={() => <ServiceUnavailable />}
              />
              <Redirect to={`${process.env.PUBLIC_URL}/`} />
            </Switch>
          </LanguageContext.Provider>
        </ScrollToTop>
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default App;
