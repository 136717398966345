import {
  Box,
  createStyles,
  Grid,
  Link,
  makeStyles,
  Theme
} from '@material-ui/core';
import React from 'react';
import Logo from '../../img/logo.png';

function ServiceUnavailable() {
  const classes = ownStyles();

  return (
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="center"
      className={classes.heroContent}
    >
      <Box className={classes.title} style={{ whiteSpace: 'pre-line' }}>
        <Link href="https://arbira.com/">
          <img src={Logo} />
        </Link>
      </Box>
      <Box className={classes.title} style={{ whiteSpace: 'pre-line' }}>
        We are sorry, the iiCasso service is temporarily unavailable.
      </Box>
    </Grid>
  );
}

const ownStyles = makeStyles((theme: Theme) =>
  createStyles({
    heroContent: {
      position: 'relative',
      zIndex: 10,
      [theme.breakpoints.up('md')]: {
        height: '548px',
        maxWidth: '840px',
        margin: '0px max(23px, calc(50vw - 420px))'
      },
      [theme.breakpoints.only('md')]: {
        height: '548px',
        maxWidth: '840px',
        margin: '0px max(23px, calc(50vw - 420px))'
      },
      [theme.breakpoints.only('sm')]: {
        maxHeight: '548px',
        maxWidth: 'max(500px, calc(95%-46px))',
        margin: '100px 20px'
      },
      [theme.breakpoints.down('xs')]: {
        maxHeight: '548px',
        maxWidth: 'max(274px, calc(90%-46px))',
        margin: '100px 20px 18vw 15px'
      }
    },
    title: {
      [theme.breakpoints.up('md')]: {
        fontFamily: 'Prompt',
        fontStyle: 'normal',
        fontSize: '28px',
        lineHeight: '120%',

        display: 'flex',
        alignItems: 'flex-end',
        textAlign: 'center',
        letterSpacing: '0.03em',
        color: '#000000',
        paddingBottom: 26
      },
      [theme.breakpoints.only('sm')]: {
        maxWidth: '400px',
        fontFamily: 'Prompt',
        fontStyle: 'normal',
        fontSize: '25px',
        lineHeight: '120%',

        display: 'flex',
        alignItems: 'flex-end',
        textAlign: 'center',
        letterSpacing: '0.03em',
        color: '#000000',
        paddingBottom: 26
      },
      [theme.breakpoints.down('xs')]: {
        fontFamily: 'Prompt',
        fontStyle: 'normal',
        fontSize: '25px',
        lineHeight: '120%',

        display: 'flex',
        alignItems: 'center',
        textAlign: 'center',
        letterSpacing: '0.03em',
        color: '#000000',
        paddingBottom: 12
      }
    }
  })
);

export default ServiceUnavailable;
